<template>
  <form
    class="dg-form"
    @submit.prevent="onSubmit"
    @keydown.enter.prevent="onSubmit"
  >
    <div class="dg-col-12">
      <DgPortalInput
        v-model="form.email"
        :placeholder="$t('PORTAL.AUTH.EMAIL', language)"
        autofocus
        variant="outline"
        prepend-icon="User"
        prepend-icon-color="#4B4B4E"
      />
      <DgPortalInput
        v-model="form.password"
        :placeholder="$t('PORTAL.AUTH.PASSWORD', language)"
        type="password"
        variant="outline"
        prepend-icon="Lock"
        prepend-icon-color="#4B4B4E"
      />
    </div>
    <div class="dg-auth__remember dg-col-12">
      <DgCheckbox
        v-model="form.remember"
        :label="$t('REMEMBER_ME', language)"
        :portalColor="portalColor"
      />
      <DgLink
        tag="router-link"
        :to="forgotPasswordPath"
        :lang="language"
        label="FORGOT_PASSWORD"
      />
    </div>
    <div class="dg-col-12">
      <DgPortalButton
        type="submit"
        label="SIGN_IN"
        :loading="loading"
        :portalColor="portalColor"
        :lang="language"
        size="md"
        full-width
      />
    </div>
  </form>
</template>
<script>
import { mapActions } from "vuex";
import { LIST, DEFAULT_LANGUAGE } from "@/constants/language";
import { ACTIONS } from "@/constants/vuex";
import { FORGOT_PASSWORD_PATH } from "@/router/routes/forgotPassword";
import store from "@/store";
import {
  DgPortalInput,
  DgCheckbox,
  DgLink,
  DgPortalButton,
} from "@engenharia/dashgoo-ui";

export default {
  name: "PageAuthLogin",
  components: {
    DgLink,
    DgPortalInput,
    DgPortalButton,
    DgCheckbox,
  },
  data: () => ({
    loading: false,
    form: {
      email: "",
      password: "",
    },
  }),
  computed: {
    forgotPasswordPath() {
      return this.$route.path + "/" + FORGOT_PASSWORD_PATH;
    },
    portalColor() {
      const portalColor = store.state.portal.settings.portalColor;
      return portalColor;
    },

    language() {
      const paramLang =
        this.$route.query.lang ||
        this.$route.params.lang ||
        this.$route.query.lng ||
        this.$route.params.lng;

      const lang = paramLang || "";
      const item = LIST.find((_) => _.value === lang) || DEFAULT_LANGUAGE;
      return item.value;
    },
  },
  methods: {
    ...mapActions({
      handleLogin: `user/${ACTIONS.USER.LOGIN}`,
    }),
    async onSubmit() {
      try {
        this.loading = true;
        const portalName = this.$route.params.id;
        this.form = {
          ...this.form,
          portalName,
        };
        await this.handleLogin(this.form);
        this.$router.push({ path: this.$route.path + "/home" });
      } catch (error) {
        this.$notifyFailed({ title: "OPS!", text: error.message });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-auth__remember {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 0px;
  margin-bottom: 11px;
}

.dg-link--primary {
  color: #525252 !important;
}

.dg-form {
  font-family: "Inter";
  & > .dg-col-12 {
    padding: 0;
  }
}
</style>
